import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App.tsx';
import AuthProvider from './providers/AuthProvider.tsx';
import { ErrorBoundary } from './components/ErrorBoundary.tsx';
import { ToastContainer } from 'react-toastify';
import NiceModal from '@ebay/nice-modal-react';

import 'react-toastify/dist/ReactToastify.css';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <NiceModal.Provider>
        <AuthProvider>
          <ErrorBoundary>
            <App />
            <ToastContainer />
          </ErrorBoundary>
        </AuthProvider>
      </NiceModal.Provider>
    </BrowserRouter>
  </React.StrictMode>,
);
