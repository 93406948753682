import { useForm } from 'react-hook-form';
import { supabase } from '../utils/supabase';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/subframe/components/Button';
import { TextField } from '@/subframe/components/TextField';
import { useAuth } from '@/providers/AuthProvider.tsx';
import { useEffect, useState } from 'react';

type FormInputs = {
  password: string;
  confirmPassword: string;
};

const ChangePassword = () => {
  const navigate = useNavigate();
  const { organization, user } = useAuth();
  const [hashError, setHashError] = useState<string | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<FormInputs>();

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    const hashParams = new URLSearchParams(hash);

    const errorCode = hashParams.get('error_code');
    const errorDescription = hashParams.get('error_description');

    if (errorCode && errorDescription) {
      setHashError(errorDescription);
    }
  }, []);

  const password = watch('password');

  useEffect(() => {
    if (user?.user_metadata?.is_password_set === true) {
      navigate('/');
    }
  }, [user, navigate]);

  const onSubmit = async (data: FormInputs) => {
    try {
      const { error } = await supabase.auth.updateUser({
        password: data.password,
        data: { is_password_set: true },
      });

      if (error) throw error;

      toast.success(
        'You successfully changed your password. You will be redirected to the home page',
      );
    } catch (error) {
      toast.error('Failed to change password. Please try again.');
    }
  };

  if (hashError) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center bg-default-background">
        <div className="flex w-full max-w-[480px] flex-col items-center justify-center gap-6 border border-solid border-neutral-border p-12 text-center">
          <div className="text-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-16 w-16 mx-auto mb-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <h2 className="text-heading-2 font-heading-2 text-default-font mb-2">Error</h2>
          <p className="text-subtext-color mb-6">{hashError}</p>
          <div className="text-caption-bold font-caption-bold text-subtext-color">
            Please contact our support team for assistance.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-full w-full flex-col items-center justify-center bg-default-background">
      <div className="flex w-full max-w-[320px] flex-col items-center justify-center gap-4 border border-solid border-neutral-border pt-12 pr-12 pb-12 pl-12">
        <div className="flex flex-col items-center justify-center gap-1">
          <span className="text-heading-2 font-heading-2 text-default-font">
            Welcome to {organization?.name}
          </span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="flex w-full flex-col gap-4">
          <div className="flex flex-col gap-2">
            <TextField helpText="">
              <TextField.Input
                type="password"
                placeholder="Type your new Password"
                {...register('password', {
                  required: 'Password is required',
                  minLength: {
                    value: 6,
                    message: 'Password must be at least 6 characters',
                  },
                })}
              />
            </TextField>
            {errors.password && (
              <span className="text-[11px] text-red-500">{errors.password.message}</span>
            )}

            <TextField helpText="">
              <TextField.Input
                type="password"
                placeholder="Confirm your new Password"
                {...register('confirmPassword', {
                  required: 'Please confirm your password',
                  validate: (value) => value === password || 'The passwords do not match',
                })}
              />
            </TextField>
            {errors.confirmPassword && (
              <span className="text-[11px] text-red-500">{errors.confirmPassword.message}</span>
            )}
          </div>
          <Button
            type="submit"
            disabled={isSubmitting}
            className="w-full rounded bg-blue-500 py-2 px-4 text-white hover:bg-blue-600 disabled:bg-blue-300"
          >
            {isSubmitting ? 'Changing...' : 'Change Password'}
          </Button>
        </form>
        <span className="text-caption-bold font-caption-bold text-subtext-color text-left w-full">
          Not working? Contact Support
        </span>
      </div>
    </div>
  );
};

export default ChangePassword;
